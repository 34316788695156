import { pathToRegexp } from 'path-to-regexp';
import { generatePath } from 'react-router-dom';

import api from 'api';
import {
  EnabledFeatureResponse,
  PROJECT_RESOURCE,
} from 'hooks/useProjectResources';
import { hasFeatureAccess } from 'lib/featuresHelper';
import { BASE_ROUTE } from 'routes/routes.constants';

const PROJECT_RESOURCES = [
  PROJECT_RESOURCE.CONVERSATIONS,
  PROJECT_RESOURCE.DATA_STORE,
  PROJECT_RESOURCE.CONVERSATION_REVIEW,
  PROJECT_RESOURCE.CALL_RECORDINGS,
  PROJECT_RESOURCE.CONVERSATIONS_LOG,
  PROJECT_RESOURCE.ANALYTICS,
  PROJECT_RESOURCE.PROJECT_SETTING,
  PROJECT_RESOURCE.SCENARIOS,
  PROJECT_RESOURCE.AGENT_CONFIG,
  PROJECT_RESOURCE.PLAYLIST_CONVERSATIONS,
];

const PROJECT_RESOURCE_PATH_MAP: { [key in PROJECT_RESOURCE]?: string } = {
  [PROJECT_RESOURCE.CONVERSATIONS]: 'flows',
  [PROJECT_RESOURCE.DATA_STORE]: 'data-store',
  [PROJECT_RESOURCE.CONVERSATION_REVIEW]: 'conversations',
  [PROJECT_RESOURCE.ANALYTICS]: 'dashboards',
  [PROJECT_RESOURCE.PROJECT_SETTING]: 'settings/project',
  [PROJECT_RESOURCE.SCENARIOS]: 'scenarios',
  [PROJECT_RESOURCE.CALL_RECORDINGS]: 'conversations-log',
  [PROJECT_RESOURCE.CONVERSATIONS_LOG]: 'conversations-log',
  [PROJECT_RESOURCE.PLAYLIST_CONVERSATIONS]: 'conversations-log',
  [PROJECT_RESOURCE.DEPLOYMENT]: 'flows',
  [PROJECT_RESOURCE.AGENT_CONFIG]: 'config',
};

export const matchNextPath = (
  currentUrl: string,
  expectedTemplate: string,
  exact: boolean = true,
) => {
  const regexp = pathToRegexp(
    expectedTemplate.replace(/\[\w+\]/g, (match) => `:${match.slice(1, -1)}`),
    undefined,
    { start: exact, end: exact },
  );

  return regexp.test(currentUrl.split('?')[0]);
};

export const generateNextPath = <Path extends string>(
  template: Path,
  urlParams?: Parameters<typeof generatePath<Path>>[1],
  queryParams?: { [key: string]: string | undefined },
) => {
  const filteredQuery = queryParams
    ? Object.keys(queryParams).reduce(
        (filt, key) =>
          queryParams[key] ? { ...filt, [key]: queryParams[key]! } : filt,
        {} as { [key: string]: string },
      )
    : {};

  if (!Object.keys(filteredQuery).length) {
    return generatePath(template, urlParams);
  }
  const query = queryParams ? new URLSearchParams(filteredQuery) : undefined;

  return `${generatePath(template, urlParams)}?${query?.toString()}`;
};

export const getResourceUrl = async (
  projectId: string,
  enabledResources: PROJECT_RESOURCE[],
): Promise<string> => {
  if (!enabledResources?.length) {
    return 'flows';
  }

  const firstEnabledResource = enabledResources[0];

  if (firstEnabledResource === PROJECT_RESOURCE.ANALYTICS) {
    const luzmoIntegration = await api.getIntegration(projectId);

    if (
      luzmoIntegration?.dashboards?.call_flows?.enabled &&
      !luzmoIntegration?.dashboards?.call_flows?.hidden
    ) {
      return `analytics/call-flows`;
    }

    if (luzmoIntegration?.embed_dashboards?.length) {
      return `analytics/${luzmoIntegration?.embed_dashboards[0].slug}`;
    }

    const gooddataDashboards = await api.getAnalyticsDashboards(projectId);

    if (!gooddataDashboards?.dashboards?.length) {
      return getResourceUrl(projectId, enabledResources.slice(1));
    }

    return `${PROJECT_RESOURCE_PATH_MAP[firstEnabledResource]}/${gooddataDashboards.dashboards[0]?.id}`;
  }

  if (firstEnabledResource === PROJECT_RESOURCE.AGENT_CONFIG) {
    const res = await api.getAgentConfigFiles(projectId);

    if (!res?.files?.length) {
      return getResourceUrl(projectId, enabledResources.slice(1));
    }

    return `${PROJECT_RESOURCE_PATH_MAP[firstEnabledResource]}/${res.files[0].id}`;
  }

  return (
    PROJECT_RESOURCE_PATH_MAP[firstEnabledResource] ??
    getResourceUrl(projectId, enabledResources.slice(1))
  );
};

export const generateProjectLandingResource = async (
  accountId: string,
  projectId: string,
) => {
  const data: EnabledFeatureResponse = await api.getEnabledFeatures(
    accountId,
    projectId,
  );

  const enabledResources = PROJECT_RESOURCES.filter((resource) => {
    return (
      !!PROJECT_RESOURCE_PATH_MAP[resource] &&
      hasFeatureAccess(resource, data.features, data.restricted_features)
    );
  });

  return getResourceUrl(projectId, enabledResources);
};

export const stripProjectRoute = (path: string) => path.replace(BASE_ROUTE, '');
