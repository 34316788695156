import { ReactNode } from "react";

import { ComponentWithChildren } from "@polyai/common/types/helpers";
import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import HelpText from "components/atoms/HelpText";
import InputError from "components/atoms/InputError";

import * as Styled from "./InputWrapper.styled";

export type InputWrapperProps = {
  label?: ReactNode;
  labelAsDiv?: boolean;
  rightLabel?: ReactNode;
  errors?: string[];
  showErrorAsTooltip?: boolean;
  afterInput?: ReactNode;
  afterErrors?: ReactNode;
  help?: ReactNode;
  "data-test-id"?: string;
  fullWidth?: boolean;
};

export const InputWrapper: ComponentWithChildren<InputWrapperProps> = ({
  label,
  rightLabel,
  errors = [],
  showErrorAsTooltip,
  help,
  afterInput,
  afterErrors,
  labelAsDiv,
  children,
  "data-test-id": dataTestId = "input-wrapper",
  fullWidth,
}) => {
  return (
    <ConditionalWrapper
      condition={!!label || !!rightLabel}
      wrapper={(children) => (
        <Styled.Label
          // This is for contenteditable inputs, labels do not work and
          // cause havoc
          $fullWidth={fullWidth}
          as={labelAsDiv ? "div" : undefined}
          data-test-id={dataTestId}
        >
          <Styled.LabelWrapper data-test-id="label-container">
            <HelpText help={help!}>{label}</HelpText>
            {rightLabel && <Styled.RightLabel>{rightLabel}</Styled.RightLabel>}
          </Styled.LabelWrapper>
          {children}
        </Styled.Label>
      )}
    >
      <div>
        <ConditionalWrapper
          condition={!!afterInput}
          wrapper={(children) => (
            <Styled.InputWrapper data-test-id="input-with-end-component">
              {children}
              {afterInput}
            </Styled.InputWrapper>
          )}
        >
          {children}
        </ConditionalWrapper>
        {!!errors?.length && (
          <Styled.ErrorWrapper>
            <InputError
              errors={errors}
              showErrorAsTooltip={showErrorAsTooltip}
            />
          </Styled.ErrorWrapper>
        )}
        {afterErrors}
      </div>
    </ConditionalWrapper>
  );
};

export default InputWrapper;
