import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AlertMessage } from '@polyai/ui/components/atoms/Alert/AlertMessage';

import api from 'api';
import { FeaturesResponse } from 'api/resources/features';
import { useAlert } from '../useAlert';
import { useIsFeatureOn } from './useIsFeatureOn';

const DEFAULT_FEATURES = [] as FeaturesResponse;

export const useFeatureSwitch = () => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { isFeatureOn, features, isLoadingFeatures } = useIsFeatureOn();

  const { mutate: createFeature, isLoading: isLoadingCreate } = useMutation<
    FeaturesResponse,
    Error,
    { name: string; user: string; description: string }
  >(
    ({ name, user, description }) => api.createFeature(name, user, description),
    {
      retry: false,
      onSuccess: (features: FeaturesResponse) => {
        queryClient.setQueryData(['features'], features);
        queryClient.invalidateQueries(['enabledFeature']);
      },
      onError: (error: Error, { name }) => {
        let message = error.message;
        if (features?.some((feature) => feature.name === name)) {
          message = `Feature named "${name}" already exists.`;
        }
        alert.error(
          <AlertMessage title="Failed to create new feature">
            {message}
          </AlertMessage>,
        );
      },
    },
  );

  const { mutate: updateFeature, isLoading: isLoadingUpdate } = useMutation<
    FeaturesResponse,
    Error,
    { name: string; user: string; enabled: boolean }
  >(({ name, user, enabled }) => api.updateFeature(name, user, enabled), {
    retry: false,
    onSuccess: (features: FeaturesResponse) => {
      queryClient.setQueryData(['features'], features);
      queryClient.invalidateQueries(['enabledFeature']);
    },
  });

  return {
    features: features ?? DEFAULT_FEATURES,
    isLoadingFeatures,
    createFeature,
    updateFeature,
    isFeatureOn,
    isLoadingCreate,
    isLoadingUpdate,
  };
};
