import { doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE, WithStatusCode } from 'api/types';
import { store } from '../../../reduxStore';
import {
  CallRecordingData,
  ConversationData,
  ConversationMetadata,
  ConversationMetadataResponse,
  ConversationsLogResponse,
  ConversationsResponse,
  CreateConversationResponse,
  CreateMessageResponse,
  LiveConversationMetadata,
  TurnReview,
} from './types';

export const NUM_CONVERSATIONS_PER_PAGE = 50;

export const getConversations = (
  queryString: string,
  limit: number = NUM_CONVERSATIONS_PER_PAGE,
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<ConversationsResponse>(
    'conversations',
    LOGGER_TYPE.CONVERSATIONS,
    {
      account_id: accountId,
      project_id: projectId,
      limit,
    },
    queryString,
  );
};

export const getLiveConversations = (cutoffMinutes: number) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<LiveConversationMetadata[]>(
    'conversations/live',
    LOGGER_TYPE.CONVERSATIONS,
    {
      account_id: accountId,
      project_id: projectId,
      cutoff_minutes: cutoffMinutes,
    },
  );
};

export const getConversation = (conversationId: string) =>
  doGet<ConversationData>(
    `conversations/${conversationId}`,
    LOGGER_TYPE.CONVERSATIONS,
  );

export const getConversationAudio = ({
  conversationId,
  redacted = false,
  download = true,
}: {
  conversationId: string;
  redacted?: boolean;
  download?: boolean;
}) => {
  return doGet(
    `conversations/${conversationId}/audio`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      redacted,
      download,
    },
    '',
    null,
    download,
  );
};

export const getConversationsLog = (queryString?: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<ConversationsLogResponse>(
    `${accountId}/${projectId}/conversations_log`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      limit: NUM_CONVERSATIONS_PER_PAGE,
    },
    queryString,
  );
};

export const getConversationsLogXlsx = (queryString?: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<Blob>(
    `${accountId}/${projectId}/conversations_log/xlsx`,
    LOGGER_TYPE.CONVERSATIONS,
    undefined,
    queryString,
    undefined,
    true,
  );
};

// Call Recording EPs used for Conversation Log
export const getCallRecording = (callId: string) =>
  doGet<CallRecordingData>(
    `call_recordings/${callId}`,
    LOGGER_TYPE.CONVERSATIONS,
  );

export const getCallRecordingAudio = ({
  callId,
  redacted = false,
  download = true,
}: {
  callId: string;
  redacted?: boolean;
  download?: boolean;
}) => {
  return doGet(
    `call_recordings/${callId}/audio`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      redacted,
      download,
    },
    '',
    null,
    download,
  );
};

export const putTurnReviews = (
  conversationId: string,
  turnId: number,
  reviews: TurnReview[],
) => {
  return doPut(
    `conversations/${conversationId}/reviews`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      turn_id: turnId,
      reviews,
    },
  );
};

export const createConversation = (
  currentPolicyVersion?: string,
  variantId?: string,
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const body = {
    account_id: accountId,
    project_id: projectId,
    client_env: 'sandbox',
    variant_id: variantId,
    // its possible that empty string is passed for git hash version since we may chat with an agent without deploying first
    version: currentPolicyVersion ?? '',
  };
  return doPost<CreateConversationResponse>(
    'conversations',
    LOGGER_TYPE.CONVERSATIONS,
    body,
  );
};

export const sendMessage = (
  conversationId: string,
  text: string,
  currentPolicyVersion?: string,
  language?: string,
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doPost<CreateMessageResponse>(
    `conversations/${conversationId}/turns`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      account_id: accountId,
      project_id: projectId,
      client_env: 'sandbox',
      version: currentPolicyVersion,
      language: language,
      asr_lang_code: language,
      tts_lang_code: language,
      message: {
        text,
      },
      metadata: {},
    },
  );
};

export const getReviewsXlsx = (conversationIds: string[]) => {
  return doPost<WithStatusCode<Blob>>(
    'conversations/reviews',
    LOGGER_TYPE.CONVERSATIONS,
    { conversation_ids: conversationIds },
    '',
    '',
    false,
    true,
    true,
  );
};

export const getAllReviewsXlsx = (queryString: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<WithStatusCode<Blob>>(
    `conversations/reviews/all`,
    LOGGER_TYPE.CONVERSATIONS,
    { account_id: accountId, project_id: projectId },
    queryString,
    '',
    true,
    true,
  );
};

export const getConversationMetadata = (conversationId: string) =>
  doGet<ConversationMetadataResponse>(
    `conversations/${conversationId}/metadata`,
    LOGGER_TYPE.CONVERSATIONS,
  );

export const putConversationMetadata = (
  conversationId: string,
  body: ConversationMetadata,
) =>
  doPut(
    `conversations/${conversationId}/metadata`,
    LOGGER_TYPE.CONVERSATIONS,
    body,
  );
