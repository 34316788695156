import { doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';

export interface Feature {
  name: string;
  description: string;
  user: string;
  enabled: boolean;
  datetime_modified: string;
}

export type FeaturesResponse = Feature[];

export const getFeatures = () => {
  return doGet('features', LOGGER_TYPE.FEATURES);
};

export const createFeature = (
  name: string,
  user: string,
  description: string,
) => {
  return doPost('features', LOGGER_TYPE.FEATURES, [
    {
      name,
      user,
      description,
      enabled: true,
    },
  ]);
};

export const updateFeature = (name: string, user: string, enabled: boolean) => {
  return doPut('features', LOGGER_TYPE.FEATURES, {
    name,
    user,
    enabled: enabled,
  });
};
